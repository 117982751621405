module.exports = [{
      plugin: require('/home/patrik/Desktop/Personal/portfolio/node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":["/","/project/gatsby-theme"],"height":3,"prependToBody":false,"color":"#2D9CDB"},
    },{
      plugin: require('/home/patrik/Desktop/Personal/portfolio/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#2D9CDB","showSpinner":false},
    },{
      plugin: require('/home/patrik/Desktop/Personal/portfolio/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
